
import { Options, Vue } from "vue-class-component";
import {
  get_all_field_list,
  edit_article,
  get_article_any,
  get_tag_list,
  uploadImages,
} from "../../api/article.api";
import { ref } from "vue";
import { mapState } from "vuex";
import { ElMessage } from "element-plus";
@Options({
  components: {},
  data() {
    return {
      id: null,
      text: "",
      fieldId: null,
      field: null,
      title: "",
      description: "",
      field_name_list: [],
      isVisiable: null,
      tag_name_list: [],
      tags: ref<string[]>([]),
      toolbar: {
        // 自定义工具栏
        customToolbar1: {
          title: "插入预定义结构",
          icon: "v-md-icon-tip",
          menus: [
            {
              name: "menu1",
              text: "静态资源地址前缀",
              action(editor: any) {
                editor.insert(function (selected: any) {
                  const prefix = "[";
                  const suffix = "](http://admin.leoyiblog.cn/files/)";
                  const placeholder = "名称";
                  const content = selected || placeholder;

                  return {
                    text: `${prefix}${content}${suffix}`,
                    selected: content,
                  };
                });
              },
            },
            {
              name: "menu2",
              text: "提示",
              action(editor: any) {
                editor.insert(function (selected: any) {
                  const prefix = "::: tip 提示\n";
                  const suffix = "\n:::";
                  const placeholder = "这是一段提示信息";
                  const content = selected || placeholder;

                  return {
                    text: `${prefix}${content}${suffix}`,
                    selected: content,
                  };
                });
              },
            },
            {
              name: "menu3",
              text: "注意",
              action(editor: any) {
                editor.insert(function (selected: any) {
                  const prefix = "::: warning 注意\n";
                  const suffix = "\n:::";
                  const placeholder = "这是一段警告";
                  const content = selected || placeholder;

                  return {
                    text: `${prefix}${content}${suffix}`,
                    selected: content,
                  };
                });
              },
            },
            {
              name: "menu4",
              text: "警告",
              action(editor: any) {
                editor.insert(function (selected: any) {
                  const prefix = "::: danger 警告\n";
                  const suffix = "\n:::";
                  const placeholder = "这是一个危险警告";
                  const content = selected || placeholder;

                  return {
                    text: `${prefix}${content}${suffix}`,
                    selected: content,
                  };
                });
              },
            },
            {
              name: "menu5",
              text: "详情",
              action(editor: any) {
                editor.insert(function (selected: any) {
                  const prefix = "::: details 详细信息\n";
                  const suffix = "\n:::";
                  const placeholder = "注意：IE / Edge 中不生效";
                  const content = selected || placeholder;

                  return {
                    text: `${prefix}${content}${suffix}`,
                    selected: content,
                  };
                });
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapState("user", ["userId"]),
  },
  async created() {
    document.title = "文章编辑 | leoyi编程日志";
    // 获取分区列表和标签列表
    this.field_name_list = await get_all_field_list();
    this.tag_name_list = await get_tag_list();
    // 获取要编辑的文章的详情
    this.id = this.$route.params.articleId;
    const article = await get_article_any({
      articleId: this.id,
    });
    if (article) {
      this.text = article.content;
      this.field = article.field;
      this.fieldId = article.fieldId + "";
      this.title = article.title;
      this.description = article.description;
      this.tags = article.tags;
      this.isVisiable = article.isVisiable;
    }
  },
  methods: {
    // 保存文章
    save(text: string, html: string) {
      // console.log("text:", text);
      // console.log("html:", html);
    },
    selectTags(val: string[]) {
      // console.log(val);
    },
    // 提交文章
    async submit() {
      // console.log(this.field);
      const res = await edit_article({
        id: this.id,
        title: this.title,
        description: this.description,
        fieldId: this.fieldId,
        tags: this.tags,
        content: this.text,
        isVisiable: this.isVisiable,
      });
      // // console.log(res);
      if (res) {
        ElMessage.success("修改保存成功");
      }
    },
    // 上传图片
    async handleUploadImage(event: any, insertImage: any, files: any) {
      const res = await uploadImages(files[0]);
      if (res) {
        insertImage({
          url: `http://admin.leoyiblog.cn${res}`,
          desc: res,
          width: "auto",
          height: "auto",
        });
      }
    },
    // ...mapActions("user", ["requestUserInfo"]),
  },
})
export default class ArticleEdit extends Vue {}
